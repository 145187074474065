import React from 'react'

export default function LockLarge(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M34.5 40.5H13.5C11.84 40.5 10.5 39.16 10.5 37.5V22.5C10.5 20.84 11.84 19.5 13.5 19.5H34.5C36.16 19.5 37.5 20.84 37.5 22.5V37.5C37.5 39.16 36.16 40.5 34.5 40.5Z'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d='M31.5 18.5V15C31.5 10.86 28.14 7.5 24 7.5C19.86 7.5 16.5 10.86 16.5 15V19.5'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path d='M24 32V36' stroke='currentColor' strokeWidth={3} strokeMiterlimit={10} />
      <path
        d='M24 31.5C25.6569 31.5 27 30.1569 27 28.5C27 26.8431 25.6569 25.5 24 25.5C22.3431 25.5 21 26.8431 21 28.5C21 30.1569 22.3431 31.5 24 31.5Z'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
