import React from 'react'

export default function NewTabSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.04337 5.3913H2.72815C1.62958 5.3913 0.739014 6.28186 0.739014 7.38043V14.0109C0.739014 15.1095 1.62958 16 2.72815 16H9.35861C10.4572 16 11.3477 15.1095 11.3477 14.0109V7.38043C11.3477 7.37807 11.3477 7.3757 11.3477 7.37334V10.6957H10.3477V14.0109C10.3477 14.5572 9.90489 15 9.35861 15H2.72815C2.18187 15 1.73901 14.5572 1.73901 14.0109V7.38043C1.73901 6.83415 2.18187 6.3913 2.72815 6.3913H6.04337V5.3913Z'
        fill='currentColor'
      />
      <path d='M5.16823 10.8825L12.2009 3.84982' stroke='currentColor' />
      <path d='M12.1835 8.36524L12.1835 3.88966L7.70747 3.88918' stroke='currentColor' />
    </svg>
  )
}
