import React from 'react'

export default function SupportMedium(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75Z'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path d='M11.9902 18V16.5' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path
        d='M9 9.74993C9 7.91993 10.65 6.45993 12.54 6.79993C13.75 7.00993 14.74 7.99993 14.95 9.20993C15.2 10.6299 14.45 11.9099 13.29 12.4599C12.51 12.8299 12 13.6199 12 14.4899V14.9999'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
