import React from 'react'

export default function SlackLogoSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.45794 10.1416C3.45794 11.0577 2.70955 11.8061 1.79342 11.8061C0.877294 11.8061 0.128906 11.0577 0.128906 10.1416C0.128906 9.22544 0.877294 8.47705 1.79342 8.47705H3.45794V10.1416Z'
        fill='currentColor'
      />
      <path
        d='M4.29688 10.1416C4.29688 9.22544 5.04526 8.47705 5.96139 8.47705C6.87752 8.47705 7.62591 9.22544 7.62591 10.1416V14.3093C7.62591 15.2254 6.87752 15.9738 5.96139 15.9738C5.04526 15.9738 4.29688 15.2254 4.29688 14.3093V10.1416Z'
        fill='currentColor'
      />
      <path
        d='M5.96139 3.45794C5.04526 3.45794 4.29688 2.70955 4.29688 1.79342C4.29688 0.877294 5.04526 0.128906 5.96139 0.128906C6.87752 0.128906 7.62591 0.877294 7.62591 1.79342V3.45794H5.96139Z'
        fill='currentColor'
      />
      <path
        d='M5.96116 4.29688C6.87729 4.29688 7.62568 5.04526 7.62568 5.96139C7.62568 6.87752 6.87729 7.62591 5.96116 7.62591H1.79342C0.877294 7.62591 0.128906 6.87752 0.128906 5.96139C0.128906 5.04526 0.877294 4.29688 1.79342 4.29688H5.96116Z'
        fill='currentColor'
      />
      <path
        d='M12.645 5.96139C12.645 5.04526 13.3934 4.29688 14.3095 4.29688C15.2257 4.29688 15.974 5.04526 15.974 5.96139C15.974 6.87752 15.2257 7.62591 14.3095 7.62591H12.645V5.96139Z'
        fill='currentColor'
      />
      <path
        d='M11.8061 5.96116C11.8061 6.87729 11.0577 7.62568 10.1416 7.62568C9.22544 7.62568 8.47705 6.87729 8.47705 5.96116V1.79342C8.47705 0.877294 9.22544 0.128906 10.1416 0.128906C11.0577 0.128906 11.8061 0.877294 11.8061 1.79342V5.96116Z'
        fill='currentColor'
      />
      <path
        d='M10.1416 12.645C11.0577 12.645 11.8061 13.3934 11.8061 14.3095C11.8061 15.2257 11.0577 15.974 10.1416 15.974C9.22544 15.974 8.47705 15.2257 8.47705 14.3095V12.645H10.1416Z'
        fill='currentColor'
      />
      <path
        d='M10.1416 11.8061C9.22544 11.8061 8.47705 11.0577 8.47705 10.1416C8.47705 9.22544 9.22544 8.47705 10.1416 8.47705H14.3093C15.2254 8.47705 15.9738 9.22544 15.9738 10.1416C15.9738 11.0577 15.2254 11.8061 14.3093 11.8061H10.1416Z'
        fill='currentColor'
      />
    </svg>
  )
}
