import React from 'react'

export default function SortDescendingSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.0098 9.75H3.00977'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d='M7.50977 14.25H3.00977'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d='M16.5098 5.25H3.00977'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path d='M17.2598 9V18' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path
        d='M20.9708 14.73L17.2608 18.44L13.5508 14.73'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
