import React from 'react'

export default function SupportSmall(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 17V15.5' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path
        d='M9.75 10C9.75 8.63 10.99 7.53 12.4 7.79C13.31 7.95 14.05 8.69 14.21 9.6C14.4 10.67 13.83 11.63 12.97 12.04C12.38 12.31 12 12.9 12 13.55V14'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d='M12 19.25C16 19.25 19.25 16 19.25 12C19.25 8 16 4.75 12 4.75C8 4.75 4.75 8 4.75 12C4.75 16 8 19.25 12 19.25Z'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
