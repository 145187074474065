import React from 'react'

export default function DesktopMedium(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.50977 20.25H16.5098'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d='M13.5098 17.25H19.5098C20.7498 17.25 21.7598 16.24 21.7598 15V6C21.7598 4.76 20.7498 3.75 19.5098 3.75H4.50977C3.26977 3.75 2.25977 4.76 2.25977 6V15C2.25977 16.24 3.26977 17.25 4.50977 17.25H9.50977C10.7498 17.25 11.7598 18.26 11.7598 19.5V20.25'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
