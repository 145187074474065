import React from 'react'

export default function NewTabLarge(props) {
  return (
    <svg
      width={48}
      height={49}
      viewBox='0 0 48 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 14.1421H6C2.68629 14.1421 0 16.8284 0 20.1421V42.1421C0 45.4558 2.68629 48.1421 6 48.1421H28C31.3137 48.1421 34 45.4558 34 42.1421V31H31V42.1421C31 43.799 29.6569 45.1421 28 45.1421H6C4.34315 45.1421 3 43.799 3 42.1421V20.1421C3 18.4853 4.34315 17.1421 6 17.1421H18V14.1421Z'
        fill='currentColor'
      />
      <path d='M17.3483 30.5812L38.5615 9.36802' stroke='currentColor' strokeWidth={3} />
      <path
        d='M38.5092 22.9883L38.5091 9.48827L25.0077 9.48682'
        stroke='currentColor'
        strokeWidth={3}
      />
    </svg>
  )
}
