import React from 'react'

export default function PreviewSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.1073 19.5031C17.5021 19.8656 16.794 20.0737 16.0368 20.0737C14.9221 20.0737 13.9132 19.6222 13.1823 18.8913C12.4515 18.1605 12 17.1516 12 16.0368C12 14.9221 12.4515 13.9132 13.1823 13.1823C13.9132 12.4515 14.9221 12 16.0368 12C17.1516 12 18.1605 12.4515 18.8913 13.1823C19.6222 13.9132 20.0737 14.9221 20.0737 16.0368C20.0737 16.7802 19.8727 17.4763 19.5229 18.0744C19.2137 18.6019 19.2633 19.2633 19.6957 19.6957L22 22'
        stroke='currentColor'
        strokeWidth={1.5}
      />
      <path
        d='M19 10V8C19 6.34315 17.6569 5 16 5H8C6.34315 5 5 6.34315 5 8V16C5 17.6569 6.34315 19 8 19H10'
        stroke='currentColor'
        strokeWidth={1.5}
      />
      <line x1={8} y1={8.75} x2={16} y2={8.75} stroke='currentColor' strokeWidth={1.5} />
      <line x1={8} y1={12.25} x2={11} y2={12.25} stroke='currentColor' strokeWidth={1.5} />
    </svg>
  )
}
