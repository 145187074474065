import React from 'react'

export default function DesktopLarge(props) {
  return (
    <svg
      width={43}
      height={35}
      viewBox='0 0 43 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24.0195 28.5H36.0195C38.4995 28.5 40.5195 26.48 40.5195 24V6C40.5195 3.52 38.4995 1.5 36.0195 1.5H6.01953C3.53953 1.5 1.51953 3.52 1.51953 6V24C1.51953 26.48 3.53953 28.5 6.01953 28.5H16.0195C18.4995 28.5 20.5195 30.52 20.5195 33V34.5'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
