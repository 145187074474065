import React from 'react'

export default function LockSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.25 20.25H6.75C5.92 20.25 5.25 19.58 5.25 18.75V11.25C5.25 10.42 5.92 9.75 6.75 9.75H17.25C18.08 9.75 18.75 10.42 18.75 11.25V18.75C18.75 19.58 18.08 20.25 17.25 20.25Z'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d='M15.75 9.25V7.5C15.75 5.43 14.07 3.75 12 3.75C9.93 3.75 8.25 5.43 8.25 7.5V9.75'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path d='M12 16V18' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path
        d='M12 15.75C12.8284 15.75 13.5 15.0784 13.5 14.25C13.5 13.4216 12.8284 12.75 12 12.75C11.1716 12.75 10.5 13.4216 10.5 14.25C10.5 15.0784 11.1716 15.75 12 15.75Z'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
