import React from 'react'

export default function SortAscendingLarge(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 14.25H3' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path d='M7.5 9.75H3' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path d='M16.5 18.75H3' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
      <path
        d='M13.5 9.29008L17.21 5.58008L20.92 9.29008'
        stroke='currentColor'
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path d='M17.25 15V6' stroke='currentColor' strokeWidth={1.5} strokeMiterlimit={10} />
    </svg>
  )
}
