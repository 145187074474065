import React from 'react'

export default function HistoryMedium(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M24 12V24L30 30' stroke='currentColor' strokeWidth={3} />
      <path d='M6.5 11V19.5H15' stroke='currentColor' strokeWidth={3} strokeMiterlimit={10} />
      <path
        d='M24 7.5C33.12 7.5 40.5 14.88 40.5 24C40.5 33.12 33.12 40.5 24 40.5C14.88 40.5 7.5 33.12 7.5 24'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d='M8.27979 19C10.3798 12.34 16.6398 7.5 23.9998 7.5'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
