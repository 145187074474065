import React from 'react'

export default function OpenLinkSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15 2H22M22 2V9M22 2L12 12' stroke='currentColor' strokeWidth={1.5} />
      <path
        d='M19 11.7586V16C19 17.6569 17.6569 19 16 19H8C6.34315 19 5 17.6569 5 16V8C5 6.34315 6.34315 5 8 5H12'
        stroke='currentColor'
        strokeWidth={1.5}
      />
    </svg>
  )
}
