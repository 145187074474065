import React from 'react'

export default function SupportLarge(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24 43.5C34.7696 43.5 43.5 34.7696 43.5 24C43.5 13.2304 34.7696 4.5 24 4.5C13.2304 4.5 4.5 13.2304 4.5 24C4.5 34.7696 13.2304 43.5 24 43.5Z'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path d='M23.9805 36V33' stroke='currentColor' strokeWidth={3} strokeMiterlimit={10} />
      <path
        d='M18 19.4999C18 15.8399 21.3 12.9199 25.08 13.5999C27.5 14.0199 29.48 15.9999 29.9 18.4199C30.4 21.2599 28.9 23.8199 26.58 24.9199C25.02 25.6599 24 27.2399 24 28.9799V29.9999'
        stroke='currentColor'
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
