import React from 'react'

export default function SlackLogoMedium(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.18691 15.2126C5.18691 16.5868 4.06433 17.7094 2.69013 17.7094C1.31594 17.7094 0.193359 16.5868 0.193359 15.2126C0.193359 13.8384 1.31594 12.7158 2.69013 12.7158H5.18691V15.2126Z'
        fill='currentColor'
      />
      <path
        d='M6.44531 15.2126C6.44531 13.8384 7.56789 12.7158 8.94209 12.7158C10.3163 12.7158 11.4389 13.8384 11.4389 15.2126V21.4642C11.4389 22.8384 10.3163 23.961 8.94209 23.961C7.56789 23.961 6.44531 22.8384 6.44531 21.4642V15.2126Z'
        fill='currentColor'
      />
      <path
        d='M8.94209 5.18691C7.56789 5.18691 6.44531 4.06433 6.44531 2.69013C6.44531 1.31594 7.56789 0.193359 8.94209 0.193359C10.3163 0.193359 11.4389 1.31594 11.4389 2.69013V5.18691H8.94209Z'
        fill='currentColor'
      />
      <path
        d='M8.94175 6.44531C10.3159 6.44531 11.4385 7.56789 11.4385 8.94209C11.4385 10.3163 10.3159 11.4389 8.94175 11.4389H2.69013C1.31594 11.4389 0.193359 10.3163 0.193359 8.94209C0.193359 7.56789 1.31594 6.44531 2.69013 6.44531H8.94175Z'
        fill='currentColor'
      />
      <path
        d='M18.9678 8.94209C18.9678 7.56789 20.0904 6.44531 21.4645 6.44531C22.8387 6.44531 23.9613 7.56789 23.9613 8.94209C23.9613 10.3163 22.8387 11.4389 21.4645 11.4389H18.9678V8.94209Z'
        fill='currentColor'
      />
      <path
        d='M17.7094 8.94175C17.7094 10.3159 16.5868 11.4385 15.2126 11.4385C13.8384 11.4385 12.7158 10.3159 12.7158 8.94175V2.69013C12.7158 1.31594 13.8384 0.193359 15.2126 0.193359C16.5868 0.193359 17.7094 1.31594 17.7094 2.69013V8.94175Z'
        fill='currentColor'
      />
      <path
        d='M15.2126 18.9678C16.5868 18.9678 17.7094 20.0904 17.7094 21.4645C17.7094 22.8387 16.5868 23.9613 15.2126 23.9613C13.8384 23.9613 12.7158 22.8387 12.7158 21.4645V18.9678H15.2126Z'
        fill='currentColor'
      />
      <path
        d='M15.2126 17.7094C13.8384 17.7094 12.7158 16.5868 12.7158 15.2126C12.7158 13.8384 13.8384 12.7158 15.2126 12.7158H21.4642C22.8384 12.7158 23.961 13.8384 23.961 15.2126C23.961 16.5868 22.8384 17.7094 21.4642 17.7094H15.2126Z'
        fill='currentColor'
      />
    </svg>
  )
}
