import React from 'react'

export default function AlertSmall(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12Z'
        stroke='currentColor'
        strokeWidth={1.5}
      />
      <line x1={12} y1={6} x2={12} y2={15} stroke='currentColor' strokeWidth={1.5} />
      <line x1={12} y1={16.5} x2={12} y2={18} stroke='currentColor' strokeWidth={1.5} />
    </svg>
  )
}
