import React from 'react'

export default function SlackLogoLarge(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.3743 30.4257C10.3743 33.1741 8.12914 35.4192 5.38076 35.4192C2.63237 35.4192 0.387207 33.1741 0.387207 30.4257C0.387207 27.6773 2.63237 25.4321 5.38076 25.4321H10.3743V30.4257Z'
        fill='currentColor'
      />
      <path
        d='M12.8906 30.4257C12.8906 27.6773 15.1358 25.4321 17.8842 25.4321C20.6326 25.4321 22.8777 27.6773 22.8777 30.4257V42.9289C22.8777 45.6773 20.6326 47.9224 17.8842 47.9224C15.1358 47.9224 12.8906 45.6773 12.8906 42.9289V30.4257Z'
        fill='currentColor'
      />
      <path
        d='M17.8842 10.3743C15.1358 10.3743 12.8906 8.12914 12.8906 5.38076C12.8906 2.63237 15.1358 0.387207 17.8842 0.387207C20.6326 0.387207 22.8777 2.63237 22.8777 5.38076V10.3743H17.8842Z'
        fill='currentColor'
      />
      <path
        d='M17.884 12.8906C20.6324 12.8906 22.8775 15.1358 22.8775 17.8842C22.8775 20.6326 20.6324 22.8777 17.884 22.8777H5.38076C2.63237 22.8777 0.387207 20.6326 0.387207 17.8842C0.387207 15.1358 2.63237 12.8906 5.38076 12.8906H17.884Z'
        fill='currentColor'
      />
      <path
        d='M37.9355 17.8842C37.9355 15.1358 40.1807 12.8906 42.9291 12.8906C45.6775 12.8906 47.9226 15.1358 47.9226 17.8842C47.9226 20.6326 45.6775 22.8777 42.9291 22.8777H37.9355V17.8842Z'
        fill='currentColor'
      />
      <path
        d='M35.4192 17.884C35.4192 20.6324 33.1741 22.8775 30.4257 22.8775C27.6773 22.8775 25.4321 20.6324 25.4321 17.884V5.38076C25.4321 2.63237 27.6773 0.387207 30.4257 0.387207C33.1741 0.387207 35.4192 2.63237 35.4192 5.38076V17.884Z'
        fill='currentColor'
      />
      <path
        d='M30.4257 37.9355C33.1741 37.9355 35.4192 40.1807 35.4192 42.9291C35.4192 45.6775 33.1741 47.9226 30.4257 47.9226C27.6773 47.9226 25.4321 45.6775 25.4321 42.9291V37.9355H30.4257Z'
        fill='currentColor'
      />
      <path
        d='M30.4257 35.4192C27.6773 35.4192 25.4321 33.1741 25.4321 30.4257C25.4321 27.6773 27.6773 25.4321 30.4257 25.4321H42.9289C45.6773 25.4321 47.9224 27.6773 47.9224 30.4257C47.9224 33.1741 45.6773 35.4192 42.9289 35.4192H30.4257Z'
        fill='currentColor'
      />
    </svg>
  )
}
