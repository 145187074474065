import React from 'react'

export default function NewTabMedium(props) {
  return (
    <svg
      width={22}
      height={23}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.19983 8.50003H3.19983C2.3714 8.50003 1.69983 9.1716 1.69983 10V20C1.69983 20.8285 2.3714 21.5 3.19983 21.5H13.1998C14.0283 21.5 14.6998 20.8285 14.6998 20V15H16.1998V20C16.1998 21.6569 14.8567 23 13.1998 23H3.19983C1.54297 23 0.199829 21.6569 0.199829 20V10C0.199829 8.34318 1.54297 7.00003 3.19983 7.00003H8.19983V8.50003Z'
        fill='currentColor'
      />
      <path d='M6.87987 15.2819L17.4865 4.67528' stroke='currentColor' strokeWidth={1.5} />
      <path
        d='M17.4603 11.4854L17.4603 4.73541L10.7096 4.73468'
        stroke='currentColor'
        strokeWidth={1.5}
      />
    </svg>
  )
}
